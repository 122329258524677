var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CsFlex,
    {
      staticClass: "model-information",
      class: { open: _setup.open },
      attrs: { vertical: "", "no-content-stretch": "" },
    },
    [
      _c(
        _setup.CsFlex,
        {
          class: ["model-information-header", "pl-3", "py-3"],
          attrs: { fillWidth: "", justify: "flex-start" },
          on: {
            click: function ($event) {
              _setup.open = !_setup.open
            },
          },
        },
        [
          _c(_setup.ChevronDownSvg, { staticClass: "mr-3" }),
          _c("span", [_vm._v(_vm._s(_vm.$t("Model information")))]),
        ],
        1
      ),
      _c("div", { staticClass: "model-information-separator closeable" }),
      _setup.open
        ? _c(
            _setup.CsFlex,
            {
              staticClass: "model-information-content pa-3",
              attrs: { "fill-width": "", gap: "0.75rem" },
            },
            [
              _c("span", { staticClass: "model-information-label" }, [
                _vm._v(_vm._s(_vm.$t("Steel bar members"))),
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    variant: "plain",
                    icon: "",
                    "x-small": "",
                    color: "gray",
                  },
                  on: { click: _setup.handleExportMaterialList },
                },
                [_c("v-icon", [_vm._v("mdi-table-arrow-right")])],
                1
              ),
              _c(_setup.ModelInformationCard, {
                attrs: {
                  loading: _setup.workerLoading,
                  compare: _setup.compare,
                  changed: _setup.numberOfBarsChanged,
                  value: _setup.numberOfBars,
                  valueSecondary: _setup.numberOfBarsSecondary,
                  label: _vm.$t("Number of bars"),
                  modelNumber: _setup.primaryModelNumber,
                  modelNumberSecondary: _setup.secondaryModelNumber,
                },
              }),
              _c(_setup.ModelInformationCard, {
                attrs: {
                  loading: _setup.workerLoading,
                  compare: _setup.compare,
                  changed: _setup.totalWeightChanged,
                  value: _setup.totalWeight,
                  unit: _setup.weightUnit,
                  valueSecondary: _setup.totalWeightSecondary,
                  label: _vm.$t("Total weight"),
                  modelNumber: _setup.primaryModelNumber,
                  modelNumberSecondary: _setup.secondaryModelNumber,
                },
              }),
              _c(_setup.ModelInformationCard, {
                attrs: {
                  loading: _setup.workerLoading,
                  compare: _setup.compare,
                  changed: _setup.totalSurfaceChanged,
                  value: _setup.totalSurface,
                  unit: _setup.surfaceUnit,
                  valueSecondary: _setup.totalSurfaceSecondary,
                  label: _vm.$t("Total surface"),
                  modelNumber: _setup.primaryModelNumber,
                  modelNumberSecondary: _setup.secondaryModelNumber,
                },
              }),
              _c(_setup.ModelInformationCard, {
                attrs: {
                  loading: _setup.workerLoading,
                  compare: _setup.compare,
                  changed: _setup.totalLengthChanged,
                  value: _setup.totalLength,
                  unit: _setup.lengthUnit,
                  valueSecondary: _setup.totalLengthSecondary,
                  label: _vm.$t("Total length"),
                  modelNumber: _setup.primaryModelNumber,
                  modelNumberSecondary: _setup.secondaryModelNumber,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "model-information-separator" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }