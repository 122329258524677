
import Vue from 'vue'
import FloatingTabItem from '@/components/FloatingTabs/FloatingTabItem.vue'
import HelpSvg from '@/assets/common/Help.svg.vue'
import InfoSvg from '@/assets/common/Info.svg.vue'
import SteelspaceLogoSvg from '@/assets/SteelspaceLogo.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import ProfilePlusSvg from '@/assets/viewer/ProfilePlus.svg.vue'
import ChangeThemeButton from '@/components/ChangeThemeButton.vue'
import ShareDialog from '@/components/Dialogs/ShareDialog.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import TabButton from '@/components/FloatingTabs/TabButton.vue'
import ToolbarMenu from '@/components/ToolbarMenu.vue'
import HelpTab from '@/components/Viewer/HelpTab.vue'
import InfoTab from '@/components/Viewer/InfoTab.vue'
import { CsBreadcrumbs, CsBtn, CsFlex } from '@consteel/csuetify'
import CompareSvg from '@/assets/viewer/Compare.svg.vue'
import { modelViewerStore } from '../../store/modelViewer.store'

export default Vue.extend({
  name: 'ModelViewerHeader',
  components: {
    ChangeThemeButton,
    CsBtn,
    CsFlex,
    CsBreadcrumbs,
    FloatingTabs,
    HelpSvg,
    HelpTab,
    InfoSvg,
    InfoTab,
    ProfilePlusSvg,
    ShareDialog,
    SteelspaceLogoSvg,
    TabButton,
    ToolbarMenu,
    FloatingTabItem,
  },
  props: {
    contentWidth: {
      required: false,
      type: [Number, String],
      default: 350,
    },
    model: {
      type: Object,
      default: null,
    },
    breadcrumbsItems: {
      type: Array,
    },
    currentModelShares: {
      type: Array,
    },
    currentModelIsShared: {
      type: Boolean,
    },
    currentModelIsPublic: {
      type: Boolean,
    },
  },
  data() {
    return {
      showShareModal: false,
      modelParentFolderName: undefined as string | undefined,
      modelId: undefined as string | undefined,
    }
  },
  methods: {
    navigateToModel(): void {
      this.$emit('navigateToModel')
    },
    navigateToCompare(): void {
      this.$emit('navigateToCompare')
    },
  },
  computed: {
    activeSideTab(): number {
      return modelViewerStore.activeSideTab
    },
    activeMainTab(): number {
      return modelViewerStore.activeMainTab
    },
    cantLoadModel(): boolean {
      return modelViewerStore.cantLoad
    },
    tabItems(): Array<Record<string, unknown>> {
      return [
        {
          icon: BoxSvg,
          text: 'Model',
          onClick: this.navigateToModel,
        },
        {
          icon: CompareSvg,
          text: 'Compare',
          onClick: this.navigateToCompare,
        },
      ]
    },
  },
})
