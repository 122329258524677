
import {
  dofToValue,
  IsDofNumeric,
  latex_units,
  roundToTwoDigits,
  roundToTwoSignificantDigits,
} from '@/services/unit.service'
import { modelViewerStore } from '@/store/modelViewer.store'
import { Get, StructuralMember } from '@consteel/storm'
import Vue from 'vue'
import InfoTable from './InfoTable.vue'
import { TableNode } from './TableNode'

export default Vue.extend({
  name: 'StructuralMemberTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): StructuralMember | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      const member = Get<StructuralMember>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        StructuralMember
      )
      return member
    },
    secondaryMember(): StructuralMember | null {
      if (!modelViewerStore.secondaryModel.rawSmadsteelModel || !this.compare) return null
      return Get<StructuralMember>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        StructuralMember
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },

    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    getTableItemsFromModelItem(item: StructuralMember | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Length'),
          value: roundToTwoDigits(item.geometry?.length),
          unit: latex_units.m,
        },
        {
          name: this.$t('Section'),
          value: item.section?.name,
          children: [
            {
              name: this.$t('Type'),
              value: item.section?.type,
            },
            {
              name: this.$t('Subtype'),
              value: item.section?.subType,
            },
            {
              name: this.$t('Group'),
              value: item.section?.groupName,
            },
            {
              name: this.$t('Description'),
              value: item.section?.description,
            },
            {
              name: this.$t('Source'),
              value: item.section?.origin,
            },
            {
              name: this.$t('Area'),
              value: roundToTwoDigits((item.section?.properties?.area ?? 0) * 1000000),
              unit: latex_units.mm2,
            },
            {
              name: this.$t('Perimeter'),
              value: roundToTwoDigits((item.section?.properties?.perimeter ?? 0) * 1000),
              unit: latex_units.mm,
            },
            ...(item.section?.parameters?.split(';').reduce((filtered, param) => {
              const paramArray = param.trim().split('=')

              if (paramArray.length === 2) {
                filtered.push({
                  name: paramArray[0],
                  value: roundToTwoDigits(Number.parseFloat(paramArray[1].replace(',', '.'))),
                  unit: latex_units.mm,
                })
              }
              return filtered
            }, [] as TableNode[]) || []),
          ],
        },
        {
          name: this.$t('Material'),
          value: item.material?.name,
          children:
            item.material?.typeName === 'STEEL'
              ? [
                  {
                    name: this.$t('Type'),
                    value: item.material?.typeName,
                  },
                  {
                    name: this.$t('Density'),
                    value: roundToTwoDigits(item.material.density),
                    unit: latex_units.kg_m3,
                  },
                  {
                    name: this.$t('Elastic modulus'),
                    value: roundToTwoDigits(item.material.elasticModulus),
                    unit: latex_units.mpa,
                  },
                  {
                    name: this.$t('Poisson factor'),
                    value: roundToTwoDigits(item.material.poissonFactor),
                  },
                  {
                    name: this.$t('Thermal expansion coefficient'),
                    value: roundToTwoSignificantDigits(item.material.thermalExpansionCoefficent),
                    unit: latex_units._c,
                  },
                  {
                    name: this.$t('Thermal expansion coefficient in fire situation'),
                    value: roundToTwoSignificantDigits(
                      item.material.thermalExpansionInFireCoefficent
                    ),
                    unit: latex_units._c,
                  },
                  {
                    name: 'Fy1',
                    value: roundToTwoDigits(item.material.fy1),
                    unit: latex_units.mpa,
                  },
                  {
                    name: 'Fy2',
                    value: roundToTwoDigits(item.material.fy2),
                    unit: latex_units.mpa,
                  },
                  {
                    name: 'Fu1',
                    value: roundToTwoDigits(item.material.fu1),
                    unit: latex_units.mpa,
                  },
                  {
                    name: 'Fu2',
                    value: roundToTwoDigits(item.material.fu2),
                    unit: latex_units.mpa,
                  },
                  {
                    name: this.$t('Thickness Y'),
                    value: roundToTwoDigits(item.material.thicknessY),
                    unit: latex_units.mm,
                  },
                  {
                    name: this.$t('Thickness U'),
                    value: roundToTwoDigits(item.material.thicknessU),
                    unit: latex_units.mm,
                  },
                ]
              : [],
        },
        {
          name: this.$t('Release start point'),
          value: item.startRelease?.name,
          children: [
            {
              name: 'x',
              value: dofToValue(item.startRelease?.x),
              unit: IsDofNumeric(dofToValue(item.startRelease?.x)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'y',
              value: dofToValue(item.startRelease?.y),
              unit: IsDofNumeric(dofToValue(item.startRelease?.y)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'z',
              value: dofToValue(item.startRelease?.z),
              unit: IsDofNumeric(dofToValue(item.startRelease?.z)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'xx',
              value: dofToValue(item.startRelease?.xx),
              unit: IsDofNumeric(dofToValue(item.startRelease?.xx))
                ? latex_units.knm_rad
                : undefined,
            },
            {
              name: 'yy',
              value: dofToValue(item.startRelease?.yy),
              unit: IsDofNumeric(dofToValue(item.startRelease?.yy))
                ? latex_units.knm_rad
                : undefined,
            },
            {
              name: 'zz',
              value: dofToValue(item.startRelease?.zz),
              unit: IsDofNumeric(dofToValue(item.startRelease?.zz))
                ? latex_units.knm_rad
                : undefined,
            },
            {
              name: 'w',
              value: dofToValue(item.startRelease?.w),
              unit: IsDofNumeric(dofToValue(item.startRelease?.w))
                ? latex_units.knm_rad
                : undefined,
            },
          ],
        },
        {
          name: this.$t('Release end point'),
          value: item.endRelease?.name,
          children: [
            {
              name: 'x',
              value: dofToValue(item.endRelease?.x),
              unit: IsDofNumeric(dofToValue(item.endRelease?.x)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'y',
              value: dofToValue(item.endRelease?.y),
              unit: IsDofNumeric(dofToValue(item.endRelease?.y)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'z',
              value: dofToValue(item.endRelease?.z),
              unit: IsDofNumeric(dofToValue(item.endRelease?.z)) ? latex_units.kn_mm : undefined,
            },
            {
              name: 'xx',
              value: dofToValue(item.endRelease?.xx),
              unit: IsDofNumeric(dofToValue(item.endRelease?.xx)) ? latex_units.knm_rad : undefined,
            },
            {
              name: 'yy',
              value: dofToValue(item.endRelease?.yy),
              unit: IsDofNumeric(dofToValue(item.endRelease?.yy)) ? latex_units.knm_rad : undefined,
            },
            {
              name: 'zz',
              value: dofToValue(item.endRelease?.zz),
              unit: IsDofNumeric(dofToValue(item.endRelease?.zz)) ? latex_units.knm_rad : undefined,
            },
            {
              name: 'w',
              value: dofToValue(item.endRelease?.w),
              unit: IsDofNumeric(dofToValue(item.endRelease?.w)) ? latex_units.knm_rad : undefined,
            },
          ],
        },
        {
          name: this.$t('Eccentricity - y'),
          value: roundToTwoDigits(item.eccentricity?.y),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Eccentricity - z'),
          value: roundToTwoDigits(item.eccentricity?.z),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Rotation'),
          value: roundToTwoDigits(item.eccentricity?.rotation),
          unit: latex_units.deg,
        },
        {
          name: this.$t('Bow imperfection L/y'),
          value: roundToTwoDigits(item.bowImperfectionLy),
        },
        {
          name: this.$t('Bow imperfection L/z'),
          value: roundToTwoDigits(item.bowImperfectionLz),
        },
        {
          name: this.$t('Type of finite element'),
          value: item.finiteElementType,
        },
        {
          name: this.$t('Tapered'),
          value: item.tapered?.taperedName,
          children: [
            {
              name: this.$t('Initial height'),
              value: roundToTwoDigits(item.tapered?.initialHeight ?? 0),
              unit: latex_units.mm,
            },
            {
              name: this.$t('End height'),
              value: roundToTwoDigits(item.tapered?.endHeight ?? 0),
              unit: latex_units.mm,
            },
            {
              name: this.$t('Format'),
              value: item.tapered?.format?.name,
            },
          ],
        },
      ]
    },
  },
})
