
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { Get, SurfaceLoad } from '@consteel/storm'
import Vue from 'vue'
import { TableNode } from './TableNode'
import InfoTable from './InfoTable.vue'
import { modelViewerStore } from '@/store/modelViewer.store'

export default Vue.extend({
  name: 'SurfaceLoadTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): SurfaceLoad | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      return Get<SurfaceLoad>(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        SurfaceLoad
      )
    },
    secondaryMember(): SurfaceLoad | null {
      if (!this.compare || !modelViewerStore.secondaryModel.rawSmadsteelModel) return null
      return Get<SurfaceLoad>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        SurfaceLoad
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },

    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    multiplicationFactor(member: SurfaceLoad | null): number {
      return member?.multiplicationFactor || 1
    },
    getTableItemsFromModelItem(item: SurfaceLoad | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.name,
        },
        {
          name: this.$t('Loadcase name'),
          value: item.loadCase?.name,
        },
        {
          name: 'x',
          value: roundToTwoDigits(
            item?.uniformForce?.x ? item.uniformForce.x * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m2,
        },
        {
          name: 'y',
          value: roundToTwoDigits(
            item?.uniformForce?.y ? item.uniformForce.y * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m2,
        },
        {
          name: 'z',
          value: roundToTwoDigits(
            item?.uniformForce?.z ? item.uniformForce.z * this.multiplicationFactor(item) : 0
          ),
          unit: latex_units.kn_m2,
        },
      ]
    },
  },
})
