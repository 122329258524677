
import { CsFlex, CsBtn } from '@consteel/csuetify'
import HelpTabItem from './Help/HelpTabItem.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'HelpTab',
  components: {
    CsFlex,
    CsBtn,
    HelpTabItem,
  },
  data() {
    return {
      navigation: [
        {
          key: this.$t('Rotate'),
          value: this.$t('Hold left mouse button'),
        },
        {
          key: this.$t('Move'),
          value: this.$t('Hold right mouse button'),
        },
        {
          key: this.$t('Zoom in/out'),
          value: this.$t('Scroll wheel up/down'),
        },
        {
          key: this.$t('Set model to X axis'),
          value: this.$t('Left mouse button on the red (x) point of the coordinate system'),
        },
        {
          key: this.$t('Set model to Y axis'),
          value: this.$t('Left mouse button on the green (y) point of the coordinate system'),
        },
        {
          key: this.$t('Set model to Z axis'),
          value: this.$t('Left mouse button on the blue (z) point of the coordinate system'),
        },
      ],
      actions: [
        {
          key: this.$t('Select an object from model'),
          value: this.$t('Ctrl + left mouse button'),
        },
        {
          key: this.$t('Deselect an object from model'),
          value: this.$t('Ctrl + right mouse button'),
        },
        {
          key: this.$t('Select multiple objects from left side panel'),
          value: this.$t('Left mouse button'),
        },
        {
          key: this.$t('Deselect an object from left side panel'),
          value: this.$t('Left mouse button on selected object again'),
        },
        {
          key: this.$t('Deselect all'),
          value: this.$t('Esc'),
        },
        {
          key: this.$t('Label an object'),
          value: this.$t('Shift + left mouse button'),
        },
      ],
      leftSidePanelOptions: [
        {
          key: this.$t('Selection tab'),
          value: this.$t(
            'Members within the model can be selected by materials and section types.'
          ),
        },
        {
          key: this.$t('Loads tab'),
          value: this.$t('The loads in different Load cases can be switched here.'),
        },
        {
          key: this.$t('Display tab'),
          value: this.$t('Display options can be set for loads, supports, and labels.'),
        },
      ],
      rightSidePanelOptions: [
        {
          key: this.$t('Sharing a model'),
          value: this.$t(
            'You can share your model by clicking on the Share button on the upper right bar of the Model Viewer interface.'
          ),
        },
        {
          key: this.$t('Sharing options:'),
          value: this.$t(
            'During sharing, you can decide whether the person can only view the shared model in Steelspace or open it in Consteel and work with it. You can also decide whether the person can only view the model or the results as well. Currently viewing results in Steelspace is not available.'
          ),
        },
        {
          key: this.$t('Model Info'),
          value: this.$t(
            'Quantity information of the whole model or the selected members can be accessed by clicking on the Info (letter i) icon. Detailed parameters of one selected member, support, or load object are listed below.'
          ),
        },
      ],
    }
  },
})
