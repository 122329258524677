var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-tab-item",
      attrs: { "fill-width": "", vertical: "", justify: "flex-start" },
    },
    [
      _c(
        "cs-flex",
        {
          class: ["subtitle", "px-5", "py-3", { open: _vm.open }],
          attrs: { justify: "flex-start" },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("v-icon", [_vm._v("mdi-chevron-down")]),
          _c("span", { staticClass: "pl-1" }, [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "text-container" },
        [
          _vm._l(_vm.items, function (item) {
            return [
              _c(
                "cs-flex",
                {
                  key: item.key,
                  staticClass: "px-5 py-2",
                  attrs: {
                    "fill-width": "",
                    justify: "space-between",
                    gap: _vm.vertical ? "0.5rem" : "1rem",
                    vertical: _vm.vertical,
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(item.key)),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(item.value)),
                  ]),
                ]
              ),
              _c("v-divider", { key: `${item.key}-divider` }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }