
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'HelpTab',
  components: {
    CsFlex,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array as () => { key: string; value: string }[],
      default: [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: true,
    }
  },
})
