var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      class: "info-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
      },
    },
    [
      _c("model-information"),
      _vm.showLoadTable
        ? _c(
            _vm.showLoadTable,
            _vm._b(
              { tag: "load-case-table" },
              "load-case-table",
              { compare: _vm.compare },
              false
            )
          )
        : _vm._e(),
      _vm.currentComponentName
        ? _c(
            _vm.currentComponentName,
            _vm._b(
              { tag: "component" },
              "component",
              { compare: _vm.compare },
              false
            )
          )
        : _vm._e(),
      _vm.showSectionTable
        ? _c("section-table", { attrs: { compare: _vm.compare } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }