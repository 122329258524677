
import { modelViewerStore } from '@/store/modelViewer.store'
import { HaunchedStructuralMember } from '@consteel/storm'
import { TableNode } from './TableNode'
import InfoTable from './InfoTable.vue'
import Vue from 'vue'
import { latex_units, roundToTwoDigits } from '@/services'
import { getHaunchedMember } from '@/modules/storm'

export default Vue.extend({
  name: 'HaunchedStructuralMemberTable',
  components: {
    InfoTable,
  },
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primaryMember(): HaunchedStructuralMember | null {
      if (!modelViewerStore.model.rawSmadsteelModel) return null

      return getHaunchedMember(
        modelViewerStore.model.rawSmadsteelModel,
        modelViewerStore.selectedIds[0]
      )
    },
    secondaryMember(): HaunchedStructuralMember | null {
      if (!modelViewerStore.secondaryModel.rawSmadsteelModel || !this.compare) return null

      return getHaunchedMember(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        modelViewerStore.selectedIds[0],
        true
      )
    },
    primaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.primaryMember)
    },
    secondaryItems(): TableNode[] {
      return this.getTableItemsFromModelItem(this.secondaryMember)
    },
  },
  methods: {
    getTableItemsFromModelItem(item: HaunchedStructuralMember | null): TableNode[] {
      if (!item) return []

      return [
        {
          name: this.$t('Name'),
          value: item.haunchedName,
        },
        {
          name: this.$t('Section'),
          value: item.section?.name,
          children: [
            {
              name: this.$t('Type'),
              value: item.section?.type,
            },
            {
              name: this.$t('Subtype'),
              value: item.section?.subType,
            },
            {
              name: this.$t('Group'),
              value: item.section?.groupName,
            },
            {
              name: this.$t('Description'),
              value: item.section?.description,
            },
            {
              name: this.$t('Source'),
              value: item.section?.origin,
            },
            {
              name: this.$t('Area'),
              value: roundToTwoDigits((item.section?.properties?.area ?? 0) * 1000000),
              unit: latex_units.mm2,
            },
            {
              name: this.$t('Perimeter'),
              value: roundToTwoDigits((item.section?.properties?.perimeter ?? 0) * 1000),
              unit: latex_units.mm,
            },
            ...(item.section?.parameters?.split(';').reduce((filtered, param) => {
              const paramArray = param.trim().split('=')

              if (paramArray.length === 2) {
                filtered.push({
                  name: paramArray[0],
                  value: roundToTwoDigits(Number.parseFloat(paramArray[1].replace(',', '.'))),
                  unit: latex_units.mm,
                })
              }
              return filtered
            }, [] as TableNode[]) || []),
          ],
        },
        {
          name: this.$t('Initial height'),
          value: roundToTwoDigits(item.initialHeight),
          unit: latex_units.mm,
        },
        {
          name: this.$t('End height'),
          value: roundToTwoDigits(item.endHeight),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Type'),
          value: item.type?.name,
        },
        {
          name: this.$t('Web thickness'),
          value: roundToTwoDigits(item.webThickness),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Flange thickness'),
          value: roundToTwoDigits(item.flangeThickness),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Flange width'),
          value: roundToTwoDigits(item.flangeWidth),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Length'),
          value: roundToTwoDigits(item.length),
          unit: latex_units.mm,
        },
        {
          name: this.$t('Format'),
          value: item.format?.name,
        },
        {
          name: this.$t('Start point'),
          value: roundToTwoDigits(item.startPoint),
          unit: latex_units.mm,
        },
      ]
    },
  },
})
