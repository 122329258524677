var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10 17H12V15H10V17ZM11 1C5.48 1 1 5.48 1 11C1 16.52 5.48 21 11 21C16.52 21 21 16.52 21 11C21 5.48 16.52 1 11 1ZM11 19C6.59 19 3 15.41 3 11C3 6.59 6.59 3 11 3C15.41 3 19 6.59 19 11C19 15.41 15.41 19 11 19ZM11 5C8.79 5 7 6.79 7 9H9C9 7.9 9.9 7 11 7C12.1 7 13 7.9 13 9C13 11 10 10.75 10 14H12C12 11.75 15 11.5 15 9C15 6.79 13.21 5 11 5Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }