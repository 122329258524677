var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "model-viewer",
      attrs: { "fill-width": "", "fill-height": "", vertical: "" },
    },
    [
      _c("model-viewer-header", {
        attrs: {
          breadcrumbsItems: _vm.breadcrumbsItems,
          model: _vm.model,
          currentModelIsPublic: _vm.currentModelIsPublic,
          currentModelIsShared: _vm.currentModelIsShared,
          currentModelShares: _vm.currentModelShares,
        },
        on: {
          changeSideTab: _vm.changeSideTab,
          navigateToCompare: _vm.navigateToCompare,
          navigateToModel: _vm.navigateToModel,
        },
      }),
      _c(
        "cs-flex",
        {
          style: `--content-width: 350px;`,
          attrs: { "fill-height": "", "fill-width": "", reverse: false },
        },
        [
          !!_vm.model
            ? _c("router-view", {
                attrs: {
                  model: _vm.model,
                  historyItems: _vm.historyItems,
                  breadcrumbItems: _vm.breadcrumbItemsForViewer,
                  floatingLabels: _vm.floatingLabels,
                  activeLoadId: _vm.activeLoadId,
                },
                on: {
                  navigateToCompare: _vm.navigateToCompare,
                  navigateToModel: _vm.navigateToModel,
                  historyItemSelect: _vm.handleHistoryItemSelect,
                  historyItemsCompare: _vm.handleHistoryItemsCompare,
                  loadAndRenderItem: _vm.loadAndRenderItem,
                },
              })
            : _vm._e(),
          _c("v-divider", { attrs: { vertical: false } }),
          _c(
            "cs-flex",
            { staticClass: "tab-content-after", attrs: { "fill-height": "" } },
            [
              _c(
                "cs-flex",
                {
                  class: [{ open: _vm.rightSideTabOpen }, "right-side-tab"],
                  attrs: { "fill-height": "", vertical: "" },
                },
                [
                  _vm.activeSideTab === 1
                    ? _c("info-tab")
                    : _vm.activeSideTab === 2
                    ? _c("help-tab", { on: { close: _vm.closeSideTab } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cs-visibility-controller", {
        class: [
          "visiblity-controller",
          _vm.rightSideTabOpen
            ? "visiblity-controller-info-tab-active"
            : "visiblity-controller-default",
        ],
        attrs: {
          viewState: _vm.xRayToggled,
          portionState: _vm.isolationState,
          labelVisibility: _vm.labelVisibility,
        },
        on: {
          labelClicked: _vm.handleVisibilityControllerLabelClick,
          chatClicked: _vm.handleVisibilityControllerCommentClick,
          viewClicked: _vm.handleVisibilityControllerViewClick,
          portionClicked: _vm.handleVisibilityControllerPortionClick,
        },
      }),
      _c("router-view", { attrs: { name: "footer" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }