var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "floating-tabs",
      attrs: {
        fillWidth: "",
        vertical: "",
        justify: "flex-start",
        align: "flex-start",
      },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "tab-header",
          attrs: { fillWidth: "", justify: "flex-start" },
        },
        [
          _c("cs-flex", { staticClass: "tab-header-before" }, [
            _c(
              "a",
              {
                staticClass: "logo-link",
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/explorer")
                  },
                },
              },
              [_c("steelspace-logo-svg", { staticClass: "ml-3 mr-10" })],
              1
            ),
          ]),
          _vm._l(_vm.tabItems, function (item, index) {
            return _c(
              "cs-flex",
              {
                key: index,
                class: [
                  { active: index === _vm.activeMainTab },
                  "floating-tab-item",
                  "py-2",
                  "px-5",
                ],
                attrs: { vertical: "" },
                on: { click: item.onClick },
              },
              [
                _c(item.icon, {
                  tag: "component",
                  staticClass: "floating-tab-icon mb-1",
                }),
                _c("span", [_vm._v(_vm._s(item.text))]),
              ],
              1
            )
          }),
          _c(
            "cs-flex",
            { staticClass: "folder-breadcrumbs" },
            [_c("cs-breadcrumbs", { attrs: { items: _vm.breadcrumbsItems } })],
            1
          ),
          _c(
            "cs-flex",
            { staticClass: "tab-header-after" },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "tab-header-after mr-8",
                  attrs: { gap: "2rem" },
                },
                [
                  _c("change-theme-button"),
                  _c("share-dialog", {
                    attrs: {
                      shares: _vm.currentModelShares,
                      selectedModel: _vm.model,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "cs-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pa-2",
                                    attrs: {
                                      small: "",
                                      disabled:
                                        _vm.currentModelIsPublic ||
                                        _vm.cantLoadModel ||
                                        _vm.currentModelIsShared,
                                    },
                                  },
                                  "cs-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "cs-flex",
                                  { attrs: { vertical: "" } },
                                  [
                                    _c("profile-plus-svg"),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Share"))),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.showShareModal,
                      callback: function ($$v) {
                        _vm.showShareModal = $$v
                      },
                      expression: "showShareModal",
                    },
                  }),
                  _c(
                    "tab-button",
                    {
                      attrs: {
                        text: _vm.$t("Info"),
                        active: _vm.activeSideTab === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("changeSideTab", 1)
                        },
                      },
                    },
                    [_c("info-svg")],
                    1
                  ),
                  _c(
                    "tab-button",
                    {
                      attrs: {
                        text: _vm.$t("Help"),
                        active: _vm.activeSideTab === 2,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("changeSideTab", 2)
                        },
                      },
                    },
                    [_c("help-svg")],
                    1
                  ),
                  _c("toolbar-menu"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("v-divider", {
        staticClass: "tab-separator",
        attrs: { vertical: false },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }