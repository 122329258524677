var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.compare
    ? _c(
        "cs-flex",
        {
          class: `model-information-card py-3 px-3 ${
            _vm.changed && "changed-border"
          }`,
          attrs: { vertical: "" },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _vm.modelNumber
              ? _c("div", { staticClass: "version-index" }, [
                  _vm._v(" " + _vm._s(_vm.modelNumber) + " "),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "version-value-cont" },
              [
                _c("span", [_vm._v(_vm._s(_vm.value))]),
                _c("cs-latex-field", { staticClass: "version-value-unit" }, [
                  _vm._v(_vm._s(_vm.unit)),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "d-flex mt-2" }, [
            _vm.modelNumberSecondary
              ? _c("div", { staticClass: "version-index" }, [
                  _vm._v(" " + _vm._s(_vm.modelNumberSecondary) + " "),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "version-value-cont" },
              [
                _c(
                  "span",
                  {
                    class: `info-value pb-2 ${_vm.changed && "changed-color"}`,
                  },
                  [_vm._v(" " + _vm._s(_vm.valueSecondary))]
                ),
                _c(
                  "cs-latex-field",
                  {
                    class: `info-value pb-2 version-value-unit ${
                      _vm.changed && "changed-color"
                    }`,
                  },
                  [_vm._v(_vm._s(_vm.unit))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "mt-1" }, [_vm._v(_vm._s(_vm.label))]),
        ]
      )
    : _c(
        "cs-flex",
        {
          staticClass: "model-information-card px-7 py-5",
          attrs: { vertical: "" },
        },
        [
          _vm.loading
            ? _c("span", { staticClass: "info-value pb-2" }, [_vm._v("...")])
            : _c("span", { staticClass: "info-value pb-2" }, [
                _vm._v(_vm._s(_vm.value)),
              ]),
          _c("cs-latex-field", { style: { "margin-left": "2px" } }, [
            _vm._v(_vm._s(_vm.unit)),
          ]),
          _c("div", [_vm._v(_vm._s(_vm.label))]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }