var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-tab",
      attrs: {
        "fill-width": "",
        "fill-height": "",
        vertical: "",
        justify: "flex-start",
      },
    },
    [
      _c(
        "cs-flex",
        { staticClass: "main-title px-5 py-3", attrs: { "fill-width": "" } },
        [
          _c(
            "cs-btn",
            {
              attrs: { primary: "", outlined: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/help")
                },
              },
            },
            [
              _c("v-icon", { staticClass: "pr-3" }, [
                _vm._v("mdi-open-in-new"),
              ]),
              _c("span", { staticClass: "title-text" }, [
                _vm._v(" " + _vm._s(_vm.$t("More Help")) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("help-tab-item", {
        attrs: { title: _vm.$t("Navigation"), items: _vm.navigation },
      }),
      _c("help-tab-item", {
        attrs: { title: _vm.$t("Actions"), items: _vm.actions },
      }),
      _c("help-tab-item", {
        attrs: {
          title: _vm.$t("Left side panel options"),
          items: _vm.leftSidePanelOptions,
          vertical: "",
        },
      }),
      _c("help-tab-item", {
        attrs: {
          title: _vm.$t("Right side panel options"),
          items: _vm.rightSidePanelOptions,
          vertical: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }