
import HelpTab from '@/components/Viewer/HelpTab.vue'
import InfoTab from '@/components/Viewer/InfoTab.vue'
import ModelViewerHeader from '@/components/Viewer/ModelViewerHeader.vue'
import { getUnreadsSubscribe, setCommentUnSubs } from '@/modules/comment'
import {
  CompareOptions,
  HistoryItem,
  compareHistoryItems,
  fetchHistoryItemById,
  fetchModelHistoryItemsById,
  getHistoryFile,
  historyStore,
  parseHistoryItems,
} from '@/modules/history'
import {
  SteelspaceModel,
  SteelspaceModelUserShare,
  getModelByIdAsync,
  getModelByIdSubscribe,
  getModelFile,
  resetModel,
} from '@/modules/model'
import { getBandwidthLimitationAsync, getBandwidthUsageAsync } from '@/modules/userService'
import { getHistoryFromCache, getModelFromCache } from '@/services/cache.service'
import {
  IsolationType,
  ModelStateNumber,
  addFloatingLabels,
  addItemsToSelection,
  getLoadCaseById,
  modelViewerStore,
  removeItemsFromSelection,
  removeSelection,
  setActiveMainTab,
  setActiveSideTab,
  setAddedItems,
  setCantLoad,
  setChangedItems,
  setCurrentViewLoading,
  setDeletedItems,
  setIsolationEnabled,
  setIsolationType,
  setLabelCallback,
  setModel,
  setModelViewerErrorMessage,
  setPickCallback,
  setPrimaryModelNumber,
  setSecondaryModel,
  setSecondaryModelNumber,
  setViewControlVisibility,
  toggleLabels,
  toggleXray,
  setSelectedCommentIds,
  removeFloatingCommentInputs,
  addFloatingCommentInput,
  removeFloatingCommentInput,
  setFilterUnreadComments,
  toggleComments,
} from '@/store/modelViewer.store'
import { CsFlex, CsVisibilityController } from '@consteel/csuetify'
import { LoadFromFile, StructuralViewBuilder } from '@consteel/stoke'
import { Color3, PickInfo, RenderContext } from '@consteel/straw'
import { ObjectData } from '@consteel/straw/src/ObjectData/ObjectData'
import { FirebaseError } from '@firebase/util'
import Vue from 'vue'

export default Vue.extend({
  name: 'ModelViewer',
  components: {
    ModelViewerHeader,
    CsVisibilityController,
    CsFlex,
    InfoTab,
    HelpTab,
  },
  data: () => {
    return {
      modelParentFolderName: undefined as string | undefined,
      labelId: 0,
    }
  },
  methods: {
    handleVisibilityControllerPortionClick(value: number): void {
      switch (value) {
        case 0:
          setIsolationEnabled(false)
          break
        case 1:
          removeSelection()
          setIsolationType(IsolationType.ghost)
          setIsolationEnabled(true, true)
          break
        case 2:
          setIsolationType(IsolationType.hideEdges)
          setIsolationEnabled(true, true)
          break
      }
    },
    handleVisibilityControllerLabelClick(value: boolean): void {
      toggleLabels(value)
    },
    handleVisibilityControllerCommentClick(value: number): void {
      setFilterUnreadComments(false)
      switch (value) {
        case 0:
          toggleComments(true)
          break
        case 1:
          setFilterUnreadComments(true)
          break
        case 2:
          toggleComments(false)
          break
      }
    },
    handleVisibilityControllerViewClick(value: boolean): void {
      toggleXray(value)
    },
    async fetchHistoryItems(): Promise<void> {
      await fetchModelHistoryItemsById(this.modelId)
    },
    checkMainTabBasedOnRoute(): void {
      if (this.$route.path.includes('compare')) {
        setActiveMainTab(1)
      } else {
        setActiveMainTab(0)
      }
    },
    onMouseMove(event): void {
      const horizontalPosition = event.clientX / event.view.innerWidth
      const verticalPosition = event.clientY / event.view.innerHeight

      setViewControlVisibility(
        horizontalPosition > 0.375 && horizontalPosition < 0.62 && verticalPosition > 0.865
      )
    },
    async loadSmadsteelFileFromId(id: string): Promise<Blob> {
      let smadsteelFile = undefined as undefined | Blob
      if (id === this.modelId) {
        smadsteelFile = await this.getSmadsteelFileFromModelId(id)
      } else {
        smadsteelFile = await this.getSmadsteelFileFromHistoryId(id)
      }

      if (!smadsteelFile) {
        throw new Error('No model file')
      }

      return smadsteelFile
    },

    async drawView(drawCallback: () => Promise<void>) {
      await drawCallback()
      await this.setCallbacksForCurrentView()
    },

    async loadAndRenderItem({
      id,
      drawCallback,
    }: {
      id: string
      drawCallback: () => Promise<void>
    }) {
      setCantLoad(false)
      setCurrentViewLoading(true)

      try {
        await this.loadItem({ id })
        await this.drawView(drawCallback)
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'permission-denied':
              setModelViewerErrorMessage(
                this.$t(`You don't have permission to view this model.`).toString()
              )
              break
            default:
              setModelViewerErrorMessage(
                this.$t(`Sorry, an unexpected error occurred. Please try again later.`).toString()
              )
              break
          }
        } else if (error instanceof Error) {
          setModelViewerErrorMessage(this.$t(error.message).toString())
        }

        setCantLoad(true)
        setCurrentViewLoading(false)
      }
    },
    async loadItem({
      id,
      modelStateNumber = ModelStateNumber.primary,
    }: {
      id: string
      modelStateNumber?: ModelStateNumber
    }) {
      const smadsteelFile = await this.loadSmadsteelFileFromId(id)
      await this.loadSmadsteelModelToStore(smadsteelFile, modelStateNumber)
    },

    async getSmadsteelFileFromModelId(modelId: string): Promise<Blob | undefined> {
      let modelDocument = new SteelspaceModel()

      if (!this.model || this.model.id !== this.modelId) {
        modelDocument = await getModelByIdAsync(modelId)
      } else {
        modelDocument = this.model
      }

      const bandwidthLimit = await getBandwidthLimitationAsync()
      const bandwidthUsage = await getBandwidthUsageAsync()

      const cachedModel = await getModelFromCache(
        (modelDocument as SteelspaceModel).id,
        (modelDocument as SteelspaceModel).lastModificationDate
      )

      if (!cachedModel && bandwidthLimit - bandwidthUsage < modelDocument?.size) {
        throw new Error('Sorry, you don’t have enough bandwidth to view this model.')
      }

      const modelFile = await getModelFile(
        modelId,
        modelDocument?.lastModificationDate ?? Date.now()
      )

      return modelFile
    },
    async getSmadsteelFileFromHistoryId(historyItemId: string): Promise<Blob | undefined> {
      const historyDocument = await fetchHistoryItemById(this.modelId, historyItemId || '')

      if (!historyDocument) return

      const smadsteelFile = historyDocument.historyItemFiles.find(
        (file) => file.extension === 'smadsteel'
      )

      const { size: fileSize, id: fileId } = smadsteelFile || {}

      const cachedHistory = await getHistoryFromCache(historyDocument?.id)

      const bandwidthLimit = await getBandwidthLimitationAsync()
      const bandwidthUsage = await getBandwidthUsageAsync()

      if (fileSize && !cachedHistory && bandwidthLimit - bandwidthUsage < fileSize) {
        throw new Error('Sorry, you don’t have enough bandwidth to view this history item.')
      }

      if (!fileId) throw new Error('There is no file id: ' + fileId)

      const historyFile = await getHistoryFile(this.modelId, historyItemId || '', fileId)

      return historyFile
    },
    async loadSmadsteelModelToStore(file: Blob, stateNumber = ModelStateNumber.primary) {
      const model = await LoadFromFile(await file.arrayBuffer())
      const viewModel = new StructuralViewBuilder(model).Build()

      if (stateNumber === ModelStateNumber.primary) {
        setModel(model, viewModel)
      } else if (stateNumber === ModelStateNumber.secondary) {
        setSecondaryModel(model, viewModel)
      }
    },

    async setCallbacksForCurrentView() {
      setPickCallback(async (pickinfo: PickInfo) => {
        const isPointOnView = modelViewerStore.currentView?.isPointOnView(pickinfo.position)

        if (!isPointOnView) return

        console.info('callback', pickinfo)

        if (modelViewerStore.secondaryModel.rawSmadsteelModel) {
          const isPrimary = pickinfo.source === 'primary'
          const isSecondary = pickinfo.source === 'secondary'
          const isSecondaryHidden = modelViewerStore.compareFadingLevel === 0
          const isPrimaryHidden = modelViewerStore.compareFadingLevel === 100
          if ((isPrimary && isPrimaryHidden) || (isSecondary && isSecondaryHidden)) return
        }

        if (pickinfo.event.button === 0 && pickinfo.event.buttons === 1) {
          const objectOnView = this.getObjectDataOnViewFromPickInfo(pickinfo)
          if (!objectOnView) return

          addItemsToSelection(objectOnView.id)
          await addFloatingCommentInput({
            position: objectOnView.centerPoint || pickinfo.position,
            id: objectOnView.id,
            objectTypeName: objectOnView.typeName,
          })
        } else if (pickinfo.event.button === 2 && pickinfo.event.buttons === 2) {
          const objectOnView = this.getObjectDataOnViewFromPickInfo(pickinfo)
          if (!objectOnView) return

          removeItemsFromSelection(objectOnView.id)
          await removeFloatingCommentInput(objectOnView.id)
        }
      })

      setLabelCallback(async (pickinfo: PickInfo) => {
        const objectOnView = this.getObjectDataOnViewFromPickInfo(pickinfo)
        if (!objectOnView) return

        await addFloatingLabels(
          objectOnView.id,
          objectOnView.typeName,
          objectOnView.centerPoint || pickinfo.position
        )
      })
    },
    getObjectDataOnViewFromPickInfo(pickinfo: PickInfo): ObjectData | undefined {
      const isPointOnView = modelViewerStore.currentView?.isPointOnView(pickinfo.position)

      if (!isPointOnView) return

      const pickedObjects = pickinfo.pickedObjects

      if (!pickedObjects || !pickedObjects.length) return

      if (modelViewerStore.isolationEnabled && modelViewerStore.isolatedIds.length) {
        //isolation is enabled
        const isolatedObject = pickedObjects.find((pickedObject) =>
          modelViewerStore.isolatedIds.includes(pickedObject.id)
        )

        return isolatedObject
      } else {
        return pickinfo.pickedObjects[0]
      }
    },
    getItemById(id: string): HistoryItem | SteelspaceModel | undefined {
      return id === this.model?.id ? this.model : this.historyItems?.find((item) => item.id === id)
    },
    async handleHistoryItemsCompare(
      sourceId: string,
      targetId: string,
      options: CompareOptions,
      callback: () => Promise<void>
    ): Promise<void> {
      setCantLoad(false)
      setCurrentViewLoading(true)
      const sourceItem = this.getItemById(sourceId)
      const targetItem = this.getItemById(targetId)

      if (this.model && sourceItem && targetItem) {
        const result = await compareHistoryItems(
          this.model.id,
          sourceItem.id,
          targetItem.id,
          options
        )
        await this.loadItem({ id: sourceId, modelStateNumber: ModelStateNumber.primary })
        await this.loadItem({ id: targetId, modelStateNumber: ModelStateNumber.secondary })

        setPrimaryModelNumber(sourceItem?.number || null)
        setSecondaryModelNumber(targetItem?.number || null)
        if (result) {
          setAddedItems(result.added)
          setChangedItems(result.changed)
          setDeletedItems(result.deleted)
          await this.drawView(callback)
        }
      }
      setCurrentViewLoading(false)
    },
    async handleHistoryItemSelect(historyItemId: string): Promise<void> {
      if (historyItemId === this.model?.id) {
        this.$router.push(`/model/${this.$route.params.modelId}`)
        return
      }

      this.$router.push(`/model/${this.$route.params.modelId}/history/${historyItemId}`)
    },

    navigateToCompare(params?: { current?: string; incoming?: string }) {
      if (modelViewerStore.activeMainTab === 1) return

      const { current, incoming } = params || {}
      this.$router.push({
        path: '/model/' + this.modelId + '/compare',
        query: { current, incoming },
      })
      setActiveMainTab(1)
    },
    navigateToModel() {
      if (modelViewerStore.activeMainTab === 0) return

      this.$router.push('/model/' + this.modelId)
      setActiveMainTab(0)
    },
    changeSideTab(index: number) {
      if (modelViewerStore.activeSideTab === index) {
        setActiveSideTab(0)
      } else {
        setActiveSideTab(index)
      }
    },
    closeSideTab() {
      setActiveSideTab(0)
    },
  },
  computed: {
    isolationState(): number {
      if (!modelViewerStore.isolationEnabled) {
        return 0
      } else {
        return modelViewerStore.isolationType === IsolationType.hideEdges ? 2 : 1
      }
    },
    getOriginModelId(): string {
      return (this.model?.isOrigin ? this.$route.params.modelId : this.model?.originModelId) || ''
    },
    labelVisibility(): boolean {
      return modelViewerStore.labelVisibility
    },
    xRayToggled(): boolean {
      return modelViewerStore.xRayToggled
    },
    selectedHistoryItem(): HistoryItem | undefined | null {
      return this.historyItems.find((item) => item.id === this.historyId)
    },
    historyItems(): HistoryItem[] {
      return parseHistoryItems(historyStore.historyItems)
    },
    activeSideTab(): number {
      return modelViewerStore.activeSideTab
    },
    modelId(): string {
      return this.$route.params.modelId
    },
    model(): SteelspaceModel | null {
      const model = getModelByIdSubscribe(this.modelId)
      const parsedModel = model ? ({ ...model } as SteelspaceModel) : null

      if (parsedModel && !!this.historyItems?.length)
        parsedModel.number = `#${this.historyItems.length + 1}*`

      return parsedModel
    },
    renderContext(): RenderContext | undefined {
      return modelViewerStore.renderContext
    },
    floatingLabels() {
      return modelViewerStore.floatingLabels
    },
    rightSideTabOpen() {
      return modelViewerStore.activeSideTab !== 0
    },
    historyId() {
      return this.$route.params.historyId || undefined
    },
    activeHistoryNumber(): string {
      if (!modelViewerStore.secondaryModel.modelNumber) {
        const historyNumber = this.selectedHistoryItem?.number

        if (historyNumber) {
          return 'version ' + historyNumber
        } else {
          return 'version ' + this.model?.number
        }
      }

      const modelNumber = modelViewerStore.model.modelNumber
      const secondaryModelNumber = modelViewerStore.secondaryModel.modelNumber
      switch (true) {
        case modelViewerStore.compareFadingLevel < 50 && !!modelNumber:
          return 'version ' + modelNumber
        case modelViewerStore.compareFadingLevel > 50 && !!secondaryModelNumber:
          return 'version ' + secondaryModelNumber
        case modelViewerStore.compareFadingLevel === 50 && !!secondaryModelNumber && !!modelNumber:
          return 'version ' + modelNumber + ' - ' + secondaryModelNumber
        default:
          return 'latest version'
      }
    },
    activeLoadId(): string {
      return modelViewerStore.activeLoadId
    },
    breadcrumbItemsForViewer(): { text: string; disabled: boolean }[] {
      if (!this.activeLoadId) return []
      const loadCase = getLoadCaseById(this.activeLoadId)

      return [
        {
          text: loadCase?.loadGroup?.name || '',
          disabled: true,
        },
        {
          text: loadCase?.name || '',
          disabled: true,
        },
      ]
    },
    breadcrumbsItems() {
      const items = [] as { text: string; id: string; disabled?: boolean }[]

      if (this.selectedHistoryItem?.title) {
        items.unshift({
          text: `${this.selectedHistoryItem.title} (${this.activeHistoryNumber})`,
          id: this.selectedHistoryItem.id || '',
        })
      } else if (this.model?.name) {
        items.unshift({
          text: `${this.model.name} (${this.activeHistoryNumber})`,
          id: this.model.name || '',
        })
      }

      if (this.modelParentFolderName) {
        items.unshift({
          text: this.modelParentFolderName,
          id: this.modelParentFolderName,
          disabled: true,
        })
      }

      if (
        this.modelParentFolderName &&
        !['My models', 'Shared with me', 'Public models'].includes(this.modelParentFolderName)
      )
        items.unshift({
          text: '...',
          id: '...',
          disabled: true,
        })

      return items
    },
    currentModelShares(): SteelspaceModelUserShare[] {
      const model = getModelByIdSubscribe(this.modelId)

      return model ? model.getShares() : []
    },
    currentModelIsPublic(): boolean {
      const model = getModelByIdSubscribe(this.modelId)

      return model?.isPublic ? model.isPublic : false
    },
    currentModelIsShared(): boolean {
      const model = getModelByIdSubscribe(this.modelId)

      return !model?.isOrigin
    },
    selectedIds() {
      return modelViewerStore.selectedIds
    },
  },
  created() {
    resetModel()
  },
  async mounted() {
    setIsolationEnabled(false, true)
    document.addEventListener('keyup', async function (evt) {
      if (evt.key === 'Escape') {
        removeSelection()
        setSelectedCommentIds([])
        await removeFloatingCommentInputs()
      }
    })
    this.checkMainTabBasedOnRoute()
    await this.fetchHistoryItems()
    getUnreadsSubscribe(this.getOriginModelId, this.$route.params.historyId)

    document.addEventListener('mousemove', this.onMouseMove)
  },
  beforeDestroy(): void {
    document.removeEventListener('keyup', function (evt) {
      if (evt.key === 'Escape') {
        removeSelection()
      }
    })
  },
  watch: {
    async $route(): Promise<void> {
      this.checkMainTabBasedOnRoute()
      setSelectedCommentIds([])
      setCommentUnSubs({})
      await removeFloatingCommentInputs()
      getUnreadsSubscribe(this.getOriginModelId, this.$route.params.historyId)
    },
    '$vuetify.theme.dark'(isDark) {
      if (isDark) {
        modelViewerStore.renderContext?.SetGizmoColors(
          new Color3(0.135, 0.15, 0.165),
          new Color3(0.25, 0.25, 0.27)
        )
      } else {
        modelViewerStore.renderContext?.SetGizmoColors(
          new Color3(217, 217, 217),
          new Color3(0.67, 0.67, 0.67)
        )
      }
    },
    model(newModel) {
      if (!newModel?.parentName) return

      this.modelParentFolderName = newModel.parentName
    },
    selectedIds(newVal: string[], oldVal: string[]) {
      if (newVal.length === 1 && !(oldVal.length > 1)) {
        setActiveSideTab(1)
      }
    },
    activeLoadId() {
      if (this.activeLoadId && modelViewerStore.activeSideTab !== 1) {
        setActiveSideTab(1)
      }
    },
  },
})
